/* client/src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #4a90e2;
  --secondary-color: #6c757d;
  --success-color: #4caf50;
  --danger-color: #f44336;
  --warning-color: #ff9800;
  --info-color: #2196f3;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #f7f9fc;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #1a73e8;
  text-decoration: underline;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* App Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Alert */
.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.alert-primary {
  background-color: #cfe2ff;
  color: #084298;
}

.alert-secondary {
  background-color: #e2e3e5;
  color: #41464b;
}

.alert-success {
  background-color: #d1e7dd;
  color: #0f5132;
}

.alert-danger {
  background-color: #f8d7da;
  color: #842029;
}

.alert-warning {
  background-color: #fff3cd;
  color: #664d03;
}

.alert-info {
  background-color: #cff4fc;
  color: #055160;
}

/* Buttons */
.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: #3a70b2;
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-success {
  background-color: var(--success-color);
  color: white;
}

.btn-success:hover {
  background-color: #3d8b40;
}

.btn-danger {
  background-color: var(--danger-color);
  color: white;
}

.btn-danger:hover {
  background-color: #d32f2f;
}

/* Forms */
.form-group {
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.form-control:focus {
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(74, 144, 226, 0.25);
}

/* Dark Theme */
body.dark-theme {
  background-color: #1a1a1a;
  color: #f0f0f0;
}

body.dark-theme .card,
body.dark-theme .dropdown-menu,
body.dark-theme .form-control,
body.dark-theme .modal-content {
  background-color: #2c2c2c;
  color: #f0f0f0;
  border-color: #444;
}

body.dark-theme .form-control {
  background-color: #333;
  color: #f0f0f0;
  border-color: #444;
}

body.dark-theme .nav-link,
body.dark-theme .navbar-brand {
  color: #f0f0f0;
}

body.dark-theme .table {
  color: #f0f0f0;
}

body.dark-theme .table th,
body.dark-theme .table td {
  border-color: #444;
}

body.dark-theme .border {
  border-color: #444 !important;
}

/* Utilities */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

/* Responsive utilities */
@media (max-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  
  .d-md-block {
    display: block !important;
  }
  
  .flex-md-column {
    flex-direction: column !important;
  }
}