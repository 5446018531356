.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: auto;
  }
  
  .modal {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow: hidden;
    position: relative;
    animation: modalFadeIn 0.3s ease;
  }
  
  .modal-small {
    width: 300px;
  }
  
  .modal-medium {
    width: 500px;
  }
  
  .modal-large {
    width: 800px;
  }
  
  .modal-fullscreen {
    width: 95vw;
    height: 90vh;
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f8f9fa;
  }
  
  .modal-header h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  
  .modal-close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 0;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    transition: color 0.2s;
  }
  
  .modal-close:hover {
    color: #333;
  }
  
  .modal-body {
    padding: 20px;
    overflow-y: auto;
    flex: 1;
  }
  
  .modal-footer {
    padding: 15px 20px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    background-color: #f8f9fa;
  }
  
  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsywność */
  @media (max-width: 860px) {
    .modal-large {
      width: 95%;
    }
  }
  
  @media (max-width: 540px) {
    .modal-medium {
      width: 95%;
    }
  }
  
  @media (max-width: 340px) {
    .modal-small {
      width: 95%;
    }
  }