.progress-container {
    width: 100%;
    margin: 10px 0;
  }
  
  .progress-bar {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .progress-fill {
    height: 100%;
    text-align: right;
    line-height: 20px;
    position: relative;
  }
  
  .progress-label {
    font-size: 12px;
    font-weight: 500;
  }
  
  .progress-label.top {
    margin-bottom: 5px;
  }
  
  .progress-label.bottom {
    margin-top: 5px;
  }
  
  .progress-label.inside {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 12px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  /* Przykładowe style dla progów kolorystycznych */
  .progress-fill.danger {
    background-color: #f44336;
  }
  
  .progress-fill.warning {
    background-color: #ff9800;
  }
  
  .progress-fill.success {
    background-color: #4caf50;
  }